<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex md5>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle; font-size: 20px !important">
										<ShowValue :object="asset" object-key="name" label="asset name"></ShowValue>
									</b>
								</span>
							</p>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<v-chip color="cyan" class="mr-2" label text-color="white">{{ asset.barcode }} </v-chip>
									<template v-if="asset.is_fault == 1">
										<v-chip color="#1bc5bd" text-color="white" label class="mr-2"> Fault </v-chip>
									</template>
									<template v-if="asset.retired == 1">
										<v-chip color="#F44336" text-color="white" label> RETIRED </v-chip>
									</template>
									<template v-else>
										<template v-if="asset.activated == 0">
											<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
										</template>
										<template v-else>
											<template v-if="asset.activated == 1">
												<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
											</template>
											<!-- <template v-if="asset.damage == 1">
												<v-chip color="blue" text-color="white" label> Support </v-chip>
											</template> -->

											<template v-if="asset.has_service == 0">
												<template v-if="asset.has_checkout == 1">
													<v-chip class="ml-2" color="orange" text-color="white" label> ISSUED </v-chip>
												</template>
												<template v-else>
													<v-chip class="ml-2" color="green" text-color="white" label> READY FOR ISSUE </v-chip>
												</template>
											</template>
										</template>
									</template>
								</span>
							</p>
						</v-flex>
						<v-flex md7 class="text-right">
							<template v-if="asset.retired">
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
										>
											<v-icon small left>mdi-more mdi-flip-h</v-icon>More
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-btn
									v-if="false"
									v-on:click="doAction('print-label')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
								>
									<v-icon small left>mdi-printer</v-icon>Print Label
								</v-btn>
							</template>
							<template v-else>
								<template v-if="asset.activated == 1 && asset.has_checkout == 0">
									<v-btn
										color="blue darken-4 text-white"
										class="mx-2"
										depressed
										tile
										v-on:click="doAction('damage')"
									>
										<v-icon small left>mdi-hammer-wrench</v-icon>Support
									</v-btn>
								</template>
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
										>
											<v-icon small left>mdi-more mdi-flip-h</v-icon>More
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('edit')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('asset:create')" v-on:click="doAction('duplicate')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-content-duplicate</v-icon>
												Clone
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('asset:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
										<!-- This is used in responsive -->
										<template>
											<template v-if="asset.has_checkout">
												<v-list-item
													v-if="getPermission('checkout:update')"
													v-on:click="doAction('extend-checkout')"
												>
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-all-outline</v-icon>
														Extend Issue
													</v-list-item-title>
												</v-list-item>
												<v-list-item v-if="getPermission('checkin:create')" v-on:click="doAction('checkin')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-outline mdi-flip-h</v-icon>
														Receive
													</v-list-item-title>
												</v-list-item>
											</template>
											<template v-else>
												<template v-if="!asset.has_service">
													<v-list-item v-if="getPermission('checkout:create')" v-on:click="doAction('checkout')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-share-outline</v-icon>
															Issue
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														v-if="getPermission('reservation:create')"
														v-on:click="doAction('reserve')"
													>
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-calendar-check</v-icon>
															Reserve
														</v-list-item-title>
													</v-list-item>
												</template>
												<template v-if="!asset.has_checkout">
													<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('retire')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
															Retire
														</v-list-item-title>
													</v-list-item>
												</template>
											</template>
										</template>
									</v-list>
								</v-menu>

								<v-menu
									v-if="getPermission('asset:cretae')"
									offset-y
									left
									bottom
									content-class="elevation-3"
									rounded="0"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-hammer-wrench</v-icon>Service
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-on:click="doAction('start-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-clock-check-outline</v-icon>
												Start
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="asset.service_uuid" v-on:click="doAction('complete-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-format-list-checks</v-icon>
												Complete
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="asset.service_uuid" v-on:click="doAction('extend-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-share-all-outline</v-icon>
												Extend
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-on:click="doAction('schedule-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-calendar-clock</v-icon>
												Schedule
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>

								<template>
									<template v-if="asset.has_checkout">
										<v-btn
											v-if="getPermission('checkout:update')"
											v-on:click="doAction('extend-checkout')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-all-outline</v-icon>Extend Issue
										</v-btn>
										<v-btn
											v-if="getPermission('checkin:create')"
											v-on:click="doAction('checkin')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-outline mdi-flip-h</v-icon>Receive
										</v-btn>
									</template>
									<template v-else>
										<!-- <template v-if="!asset.has_service">
											<v-btn
												v-if="getPermission('checkout:create')"
												v-on:click="doAction('checkout')"
												color="blue darken-4 text-white"
												class="mx-2"
												depressed
												tile
											>
												<v-icon small left>mdi-share-outline</v-icon>Issue
											</v-btn>
											<v-btn
												v-if="getPermission('reservation:create')"
												v-on:click="doAction('reserve')"
												color="blue darken-4 text-white"
												class="ml-2"
												depressed
												tile
											>
												<v-icon small left>mdi-calendar-check</v-icon>Reserve
											</v-btn>
										</template>
										<template v-if="!asset.has_checkout">
											<v-btn
												v-if="getPermission('asset:update')"
												v-on:click="doAction('retire')"
												color="blue darken-4 text-white"
												class="ml-2"
												depressed
												tile
											>
												<v-icon small left>mdi-cancel</v-icon>Retire
											</v-btn>
										</template> -->
									</template>
								</template>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="asset.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div v-if="asset && asset.pico_barcode && asset.barcode_image" style="max-width: 300px">
								<div class="d-inline-block text-center">
									<img
										style="max-width: 100%; height: 30px"
										:src="asset && asset.barcode_image"
										:alt="asset && asset.pico_barcode"
										class="cursor-pointer"
										v-on:click="barcodeDialog = true"
									/>
									<pre class="mb-0 barcode-text bold-700">{{ asset && asset.pico_barcode }}</pre>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Asset # : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="barcode" label="asset #"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Purchased On : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="purchased_on_formatted" label="purchased on">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Receive Due : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="checkout_on_formatted" label="check-in due">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> Category : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="group_relation.name" label="Category">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600 mr-2">Supplier : </span>
										<span class="fw-500">
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="asset"
													object-key="supplier_relation.display_name"
													label="supplier"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2" v-if="false">
									<div class="mt-2">
										<span class="fw-600 mr-2">Staff : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="asset"
												object-key="member_relation.display_name"
												label="incharge-officer"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Last Service : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="checkout_on_formatted" label="last service date">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> End Of Life : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="end_life_formatted" label="End Of Life">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Cost Price : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="cost_price_formatted" label="cost price">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Password : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="password" label="Password"> </ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="false" class="w-20 pr-3">
							<template v-if="asset && asset.pico_barcode && asset.barcode_image">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500">
									<Barcode circle :src="asset && asset.barcode_image"></Barcode>
								</span>
							</template>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="assetTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon>

						Details
					</v-tab>
					<v-tab href="#salesdetail" v-if="asset_setting.assetsold || asset_setting.assetrental">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon>
						Sale Details
					</v-tab>
					<v-tab v-if="getPermission('service:view')" href="#service" class="pr-8">
						<v-icon small left>mdi-hammer-wrench</v-icon>
						<v-badge color="cyan" :content="AssetServices"> Services </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('checkout:view')" href="#checked-out" class="pr-8">
						<v-icon small left>mdi-share-outline</v-icon>
						<v-badge color="cyan" :content="AssetIssue"> Issued </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('reservation:view')" href="#reservation" class="pr-8">
						<v-icon small left>mdi-calendar-check</v-icon>
						<v-badge color="cyan" :content="AssetReserve"> Reservations </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('assets:view')" href="#assetSubParts" class="pr-8">
						<v-icon small left>mdi-calendar-check</v-icon>
						<v-badge color="cyan" :content="AssetSubParts"> Sub-Parts </v-badge>
					</v-tab>
					<v-tab href="#event" class="pr-8">
						<v-icon small left>mdi-history</v-icon>
						<v-badge color="cyan" :content="AssetLog"> Logs </v-badge>
					</v-tab>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#file" class="pr-8">
						<v-icon small left>mdi-file-document-multiple</v-icon>
						<v-badge color="cyan" :content="AssetFile"> Files </v-badge>
					</v-tab>
					<!-- <v-tab v-if="getPermission('asset:update')" href="#service_report" class="pr-8">
						<v-icon small left>mdi-file-document-multiple</v-icon>
						<v-badge color="cyan" :content="AssetServiceReport"> Service Report </v-badge>
					</v-tab> -->
					<v-tab v-if="getPermission('asset:update')" href="#comment" class="pr-8">
						<v-icon small left>mdi-comment-multiple</v-icon>
						<v-badge color="cyan" :content="Assetcomment"> Comments </v-badge>
					</v-tab>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="assetTab">
					<v-tab-item value="detail">
						<div class="mx-4 mt-3">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<div class="w-100 d-flex">
									<div class="w-55 mr-2 light-gradient-blue-bg">
										<v-layout
											class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
											style="background-color: #a6cdf3"
										>
											<v-flex class="font-level-3-bold my-auto">
												<span class="detail-svg-icon mr-2">
													<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
												</span>
												Asset Details
											</v-flex>
										</v-layout>
										<table width="100%" class="detail-table scattered-table ml-2">
											<tr>
												<th width="250">Tags</th>
												<td width="8">:</td>
												<td>
													<template v-if="asset && asset.tags">
														<v-chip
															v-for="(tagRow, tagIndex) in asset.tags"
															:key="`tag-${tagIndex}`"
															color="#58b5bd"
															text-color="white"
															class="ml-1"
														>
															{{ tagRow.tag }}
														</v-chip>
													</template>
												</td>
											</tr>
											<tr>
												<th width="250">Contact for Item</th>
												<td width="8">:</td>
												<td>
													<ShowValue :object="asset" object-key="contact" label="Contact for Item"></ShowValue>
												</td>
											</tr>
											<tr>
												<th width="250">Is Return After End Life</th>
												<td width="8">:</td>
												<td>
													{{ asset && asset.is_return == 1 ? "Yes" : "No" }}
												</td>
											</tr>
											<tr>
												<th width="250">Created On</th>
												<td width="8">:</td>
												<td>
													<ShowValue
														:object="asset"
														object-key="created_at"
														tooltip
														tooltip-value="added_at_formatted"
														label="created on"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th width="250">Created By</th>
												<td width="8">:</td>
												<td>
													<ShowValue
														:object="asset"
														object-key="added_by.display_name"
														label="added by"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th width="250">Remark</th>
												<td width="8">:</td>
												<td>
													<ShowValue :object="asset" object-key="remark" label="remark"> </ShowValue>
												</td>
											</tr>
											<tr>
												<th width="250">Description</th>
												<td width="8">:</td>
												<td>
													<ShowValue :object="asset" object-key="description" label="description"> </ShowValue>
												</td>
											</tr>

											<tr v-if="false">
												<th width="250px">Last Service Date</th>
												<td width="8">:</td>
												<td>
													<ShowValue
														:object="asset"
														object-key="last_service_date"
														label="last service date"
													></ShowValue>
												</td>
												<th width="250px">Upcoming Service Date</th>
												<td width="8">:</td>
												<td>
													<ShowValue
														:object="asset"
														object-key="upcoming_service_date"
														label="upcoming service date"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<template v-if="false">
													<th width="250px">
														Age
														<TooltipQuestion>
															<template v-slot:text>Age is calculated based on the purchase date</template>
														</TooltipQuestion>
													</th>
													<td width="8">:</td>
													<td>
														<ShowValue :object="asset" object-key="age" label="age"></ShowValue>
													</td>
												</template>
											</tr>
										</table>
									</div>
									<div class="w-45 ml-2">
										<div class="light-green-bg">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #c9e391"
											>
												<v-flex class="font-level-3-bold my-auto">
													<span class="detail-svg-icon mr-2">
														<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
													</span>
													Warranty Details
													<v-chip
														v-if="asset && checkWExpDate(asset.warranty_end_date) && false"
														color="red"
														text-color="white"
														class="ml-1"
													>
														Expired
													</v-chip>
												</v-flex>
											</v-layout>
											<div class="expired_details">
												<table width="100%" class="detail-table scattered-table ml-2">
													<tr>
														<th width="250">Warranty Start Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="asset"
																object-key="warranty_start_date_formatted"
																label="warranty Start Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250">Warranty End Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="asset"
																object-key="warranty_end_date_formatted"
																label="warranty End Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Warranty Reminder Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="asset"
																object-key="warranty_reminder_date_formatted"
																label="warranty Reminder Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Warranty Reminder End Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="asset"
																object-key="warranty_reminder_endDate_formatted"
																label="Warranty Reminder End Date"
															></ShowValue>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div class="mt-4 light-orange-bg">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #ffb760"
											>
												<v-flex class="font-level-3-bold my-auto">
													<span class="detail-svg-icon mr-2">
														<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
													</span>
													Maintenance Details
												</v-flex>
											</v-layout>
											<table width="100%" class="detail-table scattered-table ml-2">
												<tr>
													<th width="250">Maintenance Start Date</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="asset"
															object-key="maintenance_start_date_formatted"
															label="Maintenance Start Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Maintenance End Date</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="asset"
															object-key="maintenance_end_date_formatted"
															label="Maintenance End Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250px">Maintenance Reminder Date</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="asset"
															object-key="maintenance_reminder_date_formatted"
															label="Maintenance Reminder Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250px">Maintenance Reminder End Date</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="asset"
															object-key="maintenance_reminder_endDate_formatted"
															label="Maintenance Reminder End Date"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250px">Maintenance By</th>
													<td width="8">:</td>
													<td>
														<template v-if="asset.maintenance_by">
															<ShowValue
																:object="asset"
																object-key="maintenance_by_relation.display_name"
																label="Maintenance By"
															></ShowValue>
															(Supplier)
														</template>
														<template v-else>
															<ShowValue
																:object="asset"
																object-key="member.display_name"
																label="Maintenance By"
															></ShowValue>
															(Inchagre Officer)
														</template>
													</td>
												</tr>
												<tr>
													<th width="250px">Cost of Maintenance</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="asset"
															object-key="maintenance_cost_formatted"
															label="Cost of Maintenance"
														></ShowValue>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="salesdetail" v-if="asset_setting.assetsold || asset_setting.assetrental">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Asset Sales Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th colspan="4" class="table_heading">Sold Details</th>
									</tr>
									<tr>
										<th width="15%">Sold Price</th>
										<td width="25%">
											<ShowValue
												:object="asset"
												object-key="sold_price_formatted"
												label="Sold Price"
											></ShowValue>
										</td>
										<th width="15%">Is Serviceable</th>
										<td width="35%">
											{{ asset && asset.is_sold_service == 1 ? "Yes" : "No" }}
										</td>
									</tr>
								</table>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th colspan="4" class="table_heading">Rental Details</th>
									</tr>
									<tr>
										<th width="15%">Min Period</th>
										<td width="25%">
											<tempalte v-if="asset && asset.min_period">
												{{ asset.min_period }} {{ asset.min_period_type }}
											</tempalte>
											<tempalte v-else> no min period </tempalte>
										</td>
										<th width="15%">Max Period</th>
										<td width="35%">
											<tempalte v-if="asset && asset.max_period">
												{{ asset.max_period }} {{ asset.max_period_type }}
											</tempalte>
											<tempalte v-else> no max period </tempalte>
										</td>
									</tr>
									<tr>
										<th width="15%">Delay Between Rentals</th>
										<td width="25%">
											<tempalte v-if="asset && asset.rental_delay_period">
												{{ asset.rental_delay_period }} {{ asset.rental_delay_period_type }}
											</tempalte>
											<tempalte v-else> no delay period </tempalte>
										</td>
										<th width="15%">Per Minute Price</th>
										<td width="25%">
											<ShowValue
												:object="asset"
												object-key="minute_rental_price_formatted"
												label="per minute price"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="15%">Per Hour Price</th>
										<td width="25%">
											<ShowValue
												:object="asset"
												object-key="hour_rental_price_formatted"
												label="per hour price"
											></ShowValue>
										</td>
										<th width="15%">Per Day Price</th>
										<td width="35%">
											<ShowValue
												:object="asset"
												object-key="day_rental_price_formatted"
												label="per day price"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="15%">Per Week Price</th>
										<td width="25%">
											<ShowValue
												:object="asset"
												object-key="week_rental_price_formatted"
												label="per week price"
											></ShowValue>
										</td>
										<th width="15%">Per Month Price</th>
										<td width="35%">
											<ShowValue
												:object="asset"
												object-key="month_rental_price_formatted"
												label="per month price"
											></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="service">
						<AssetServiceListing v-if="assetTab == 'service'" class="mx-4" :asset-uuid="uuid">
						</AssetServiceListing>
					</v-tab-item>
					<v-tab-item value="checked-out">
						<ReservationListing
							v-if="assetTab == 'checked-out'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation">
						<ReservationListing
							v-if="assetTab == 'reservation'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="assetSubParts">
						<SubPartsListing
							v-if="assetTab == 'assetSubParts'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:is-parent-retired="asset.retired == 1 ? true : false"
							:type-uuid="uuid"
							:type-data="asset"
							status="reserved"
						></SubPartsListing>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="assetTab == 'event'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item v-if="false" value="work-order">
						<WorkOrderListing
							v-if="assetTab == 'work-order'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="assetTab == 'file'"
							class="mx-4"
							type-text="Asset Files"
							type="asset"
							:type-uuid="uuid"
							:type-id="assetId"
							:extensive="true"
							:is_main="true"
						>
						</Files>
					</v-tab-item>
					<!-- <v-tab-item value="service_report">
						<Files
							v-if="assetTab == 'service_report'"
							class="mx-4"
							type-text="Asset Service Report"
							type="service-report"
							:type-uuid="uuid"
						></Files>
					</v-tab-item> -->
					<v-tab-item value="comment">
						<Comments
							v-if="assetTab == 'comment'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="assetTab == 'support'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Asset"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!asset.retired ? 'To preserve associated records, you could retire the Asset instead.' : null
				"
			>
				<template v-if="!asset.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<RetireTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="goBack()"
				v-on:close="retireDialog = false"
				:retire-dialog="retireDialog"
			></RetireTemplate>
			<ExtendCheckoutTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:checkout-uuid="asset.checkout_uuid"
				v-on:success="
					extendCheckoutDialog = false;
					getAsset();
				"
				v-on:close="extendCheckoutDialog = false"
				:extend-checkout-dialog="extendCheckoutDialog"
			>
			</ExtendCheckoutTemplate>
			<CheckoutTemplate
				type="Asset"
				endpoint="asset"
				:damage-asset="asset.damage"
				:type-uuid="uuid"
				v-on:success="
					checkoutDialog = false;
					getAsset();
				"
				v-on:close="checkoutDialog = false"
				:checkout-dialog="checkoutDialog"
			></CheckoutTemplate>
			<DamageTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					damageDialog = false;
					getAsset();
				"
				v-on:close="damageDialog = false"
				:checkout-dialog="damageDialog"
			></DamageTemplate>
			<template v-if="checkinDialog && uuid && asset && asset.checkout_uuid">
				<CheckinTemplate
					type="Asset"
					endpoint="asset"
					:type-uuid="uuid"
					:checkout-uuid="asset.checkout_uuid"
					v-on:success="
						checkinDialog = false;
						getAsset();
					"
					v-on:close="checkinDialog = false"
					:checkin-dialog="checkinDialog"
				></CheckinTemplate>
			</template>
			<ReservationTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					reservationDialog = false;
					getAsset();
				"
				v-on:close="reservationDialog = false"
				:reservation-dialog="reservationDialog"
			></ReservationTemplate>
			<ServiceStartTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					serviceDialog = false;
					getAsset();
				"
				v-on:close="serviceDialog = false"
				:service-dialog="serviceDialog"
			></ServiceStartTemplate>
			<ServiceScheduleTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					serviceScheduleDialog = false;
					getAsset();
				"
				v-on:close="serviceScheduleDialog = false"
				:service-dialog="serviceScheduleDialog"
			></ServiceScheduleTemplate>
			<ServiceCompleteTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:service-uuid="asset.service_uuid"
				v-on:success="
					serviceCompleteDialog = false;
					getServices();
					getAsset();
				"
				v-on:close="serviceCompleteDialog = false"
				:service-dialog="serviceCompleteDialog"
			></ServiceCompleteTemplate>
			<ServiceExtendTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:service-uuid="asset.service_uuid"
				v-on:success="
					serviceExtendDialog = false;
					getServices();
					getAsset();
				"
				v-on:close="serviceExtendDialog = false"
				:service-dialog="serviceExtendDialog"
			></ServiceExtendTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
		<BarcodeDialog 
			:dialog="barcodeDialog"
			:title="`${asset && asset.name} (#${asset && asset.barcode})`"
			:barcode-text="asset && asset.pico_barcode"
			:barcode-image="asset && asset.barcode_image"
			v-on:close="barcodeDialog = false"
		>
		</BarcodeDialog>
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RetireTemplate from "@/view/components/RetireTemplate";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import DamageTemplate from "@/view/components/DamageTemplate";
import ExtendCheckoutTemplate from "@/view/components/ExtendCheckoutTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import ServiceStartTemplate from "@/view/components/ServiceStartTemplate";
import ServiceScheduleTemplate from "@/view/components/ServiceScheduleTemplate";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import ReservationListing from "@/view/components/ReservationListing";
import SubPartsListing from "@/view/components/SubPartsListing";
import EventListing from "@/view/components/EventListing";
import AssetServiceListing from "@/view/components/AssetServiceListing";
import { GetAsset } from "@/core/lib/asset.lib";
import ServiceCompleteTemplate from "@/view/components/ServiceCompleteTemplate";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import ApiService from "@/core/services/api.service";
import BarcodeDialog from "@/view/components/BarcodeDialog";
import MomentJS from "moment-timezone";

export default {
	name: "asset-detail",
	title: "Detail Asset",
	data() {
		return {
			extendCheckoutDialog: false,
			checkinDialog: false,
			serviceDialog: false,
			reservationDialog: false,
			serviceScheduleDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			checkoutDialog: false,
			damageDialog: false,
			retireDialog: false,
			deleteDialog: false,
			deleteURL: null,
			deleteText: null,
			assetTab: "details",
			uuid: null,
			assetId: 0,
			Barcode: null,
			contentLoaded: false,
			pageLoading: true,
			asset: {},
			deviceWidth: document.documentElement.clientWidth,
			AssetIssue: "0",
			AssetReserve: "0",
			AssetSubParts: "0",
			AssetServices: "0",
			Assetcomment: "0",
			AssetServiceReport: "0",
			AssetLog: "0",
			AssetFile: "0",
			asset_setting: {},
			barcodeDialog: false,
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		checkWExpDate(date) {
			let currentData = MomentJS();
			let enddate = MomentJS(date);
			if (enddate.diff(currentData, "days") > 0) {
				return false;
			}
			return true;
		},
		damageAsset() {
			this.damageDialog = true;
		},
		getAssetCount() {
			ApiService.setHeader();
			ApiService.get(`asset-detail-count/${this.asset.id}`)
				.then(({ data }) => {
					this.AssetIssue = data.AssetIssue.toString();
					this.AssetReserve = data.AssetReserve.toString();
					this.AssetSubParts = data.AssetSubParts.toString();
					this.AssetServices = data.AssetServices.toString();
					this.AssetFile = data.AssetFile.toString();
					(this.Assetcomment = data.Assetcomment.toString()),
						(this.AssetServiceReport = data.AssetServiceReport.toString()),
						(this.AssetLog = data.AssetLog.toString());
				})
				.catch(() => {});
		},
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "asset-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "asset-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "damage":
					this.damageDialog = true;
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "extend-checkout":
					this.extendCheckoutDialog = true;
					break;
				case "checkin":
					this.checkinDialog = true;
					break;
				case "start-service":
					this.serviceDialog = true;
					break;
				case "schedule-service":
					this.serviceScheduleDialog = true;
					break;
				case "complete-service":
					this.serviceCompleteDialog = true;
					break;
				case "extend-service":
					this.serviceExtendDialog = true;
					break;
			}
		},
		getServices() {
			EventBus.$emit("reload:asset-service", true);
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset = data;
					this.assetId = data.id;
					this.getAssetCount();
					this.deleteText = `#${data && data.barcode} - ${data && data.name}`;
					this.deleteURL = `asset/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		ShowValue,
		Comments,
		DeleteTemplate,
		RetireTemplate,
		CheckinTemplate,
		CheckoutTemplate,
		ExtendCheckoutTemplate,
		ServiceStartTemplate,
		ServiceScheduleTemplate,
		ReservationListing,
		SubPartsListing,
		AssetServiceListing,
		ServiceCompleteTemplate,
		SupportTemplate,
		ServiceExtendTemplate,
		WorkOrderListing,
		EventListing,
		ReservationTemplate,
		TooltipQuestion,
		ImageTemplate,
		Barcode,
		DamageTemplate,
		BarcodeDialog,
	},
	computed: {
		...mapGetters(["localDB"]),
	},
	mounted() {
		this.asset_setting = this.localDB("asset_setting", {});
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getAsset();

		EventBus.$on("reload:asset", () => {
			this.getAsset();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>