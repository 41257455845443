<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md9 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Sub-Parts
			</v-flex>
			<v-flex md3 class="text-right my-auto">
				<v-btn
					v-if="!isParentRetired"
					color="blue darken-4 text-white"
					class="mx-2"
					depressed
					tile
					v-on:click="addDialog = true"
				>
					<v-icon small left>mdi-plus</v-icon>Add Sub-Parts
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="5%" class="p-2 light-blue-bg">Action</th>
						<th width="10%" class="p-2 light-blue-bg">Image</th>
						<th width="9%" class="p-2 light-blue-bg">Sub-Part #</th>
						<th width="15%" class="p-2 light-blue-bg">Name</th>
						<th width="8%" class="p-2 light-blue-bg">Cost Price</th>
						<th width="8%" class="p-2 light-blue-bg">Quantity</th>
						<th width="15%" class="p-2 light-blue-bg">Supplier Info</th>
						<th width="10%" class="p-2 light-blue-bg">Purchased On</th>
						<th width="15%" class="p-2 light-blue-bg">Comments</th>
					</tr>
				</thead>
				<tbody v-if="subparts.length">
					<tr
						v-for="(row, index) in subparts"
						:key="index"
						class="cursor-pointer"
						v-on:click="getSubPartDetail(row.uuid)"
					>
						<td class="p-2 border-top-light-grey">
							<v-tooltip v-if="false" top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										x-small
										color="green"
										v-bind="attrs"
										v-on="on"
										class="mr-3"
										v-on:click.stop.prevent="routeToUpdate(row.uuid)"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit Sub-Part</span>
							</v-tooltip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										x-small
										color="red"
										text-color="white"
										v-bind="attrs"
										v-on="on"
										v-on:click.stop.prevent="deleteConfirm(row)"
									>
										<v-icon color="white">mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete Sub-Part</span>
							</v-tooltip>
						</td>
						<td class="p-2 border-top-light-grey">
							<ImageTemplate circle style="max-width: 50px" :src="row.image"> </ImageTemplate>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="barcode" label="barcode"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="name" label="name"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="cost" label="cost"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="quantity" label="quantity"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<label class="font-size-14 mb-1"
											><b>
												<v-icon size="18">mdi-account-circle</v-icon>
											</b></label
										>
										{{ row.supplier }}
									</div>

									<div>
										<label class="font-size-14 mb-1"
											><b>
												<v-icon size="18">mdi-phone</v-icon>
											</b></label
										>
										{{ row.contact }}
									</div>
								</div>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="purchased_date" label="purchased_date"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey d-grid">
							<ShowValue :object="row" object-key="description" truncate label="comments"></ShowValue>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="9">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no Sub-Parts at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<AddSubParts
			type="asset"
			:sub-part-uuid="uuid"
			:type-id="typeId"
			:type-data="typeData"
			v-on:success="successAddParts()"
			v-on:close="addDialog = false"
			:sub-part-dialog="addDialog"
		></AddSubParts>
		<DetailSubPart
			type="Sub-Part"
			:sub-part-uuid="uuid"
			:is-parent-retired="isParentRetired"
			:uuid="uuid"
			v-on:close="detailDialog = false"
			:dialog="detailDialog"
		></DetailSubPart>
		<Dialog :dialog="cancelDialog" :dialog-width="dialogWidth">
			<template v-slot:title>Confirm Cancel Reservation</template>
			<template v-slot:body>
				<p class="m-0 font-level-3">Are you sure you want to cancel the Reservation?</p>
			</template>
			<template v-slot:action>
				<v-btn :disabled="cancelLoading" class="mr-2" v-on:click="cancelDialog = false" depressed tile
					>Cancel</v-btn
				>
				<v-btn
					:disabled="cancelLoading"
					:loading="cancelLoading"
					color="blue darken-4 text-white"
					depressed
					tile
					>Confirm</v-btn
				>
			</template>
		</Dialog>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Sub-Part</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">Sub-Part </span>
							<span class="red--text font-bolder lighten-1">#{{ deleteText }}</span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteSubPart"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import AddSubParts from "@/view/components/AddSubParts";
import DetailSubPart from "@/view/components/Detail-SubPart";
import ImageTemplate from "@/view/components/Image";

export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
		typeData: {
			type: Object,
			default: null,
		},
		isParentRetired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			statusList: [
				{
					text: "Issued",
					value: "checked-out",
				},
				{
					text: "Overdue",
					value: "overdue",
				},
			],
			status_filter: "checked-out",
			subparts: [],
			reservation: {},
			checkoutDialog: false,
			cancelDialog: false,
			pageLoading: true,
			approveLoading: false,
			cancelLoading: false,
			addDialog: false,
			uuid: null,
			typeId: 0,
			typeUuid: null,
			detailDialog: false,
			deleteDialog: false,
			deleteUUID: null,
			deleteText: null,
			deleteButton: false
		};
	},
	methods: {
		deleteSubPart() {
			this.deleteButton = true;
			ApiService.delete(`subpart/${this.deleteUUID}`)
				.then(() => {
					this.getSubParts();
					this.deleteClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
		deleteClear() {
			this.deleteDialog = false;
			this.deleteUUID = null;
			this.deleteText = null;
		},
		deleteConfirm({ uuid, barcode }) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteText = barcode;
		},
		routeToUpdate(uuid) {
			console.log(uuid);
		},
		successAddParts() {
			this.addDialog = false;
			this.getSubParts();
		},
		getSubPartDetail(uuid) {
			this.uuid = uuid;
			this.detailDialog = true;
		},
		downloadFile(row) {
			window.open(row.signed_pdf, "_blank");
		},
		doAction(row, param) {
			this.reservation = row;
			switch (param) {
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "cancel":
					this.cancelDialog = true;
					break;
			}
		},
		getSubParts() {
			ApiService.query(`subpart/${this.type}/${this.typeUuid}`)
				.then(({ data }) => {
					this.subparts = data.rows;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		Dialog,
		ShowValue,
		AddSubParts,
		ImageTemplate,
		DetailSubPart,
	},
	mounted() {
		this.typeUuid = this.typeData.uuid;
		this.getSubParts();
		EventBus.$on("reload:asset-reservation", () => {
			this.getSubParts();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-reservation");
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
