<template>
	<Dialog :dialog="checkoutDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Damage {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="checkoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="checkout-dialog">
					<v-col md="12" v-if="relatedDetails && relatedDetails.name">
						<perfect-scrollbar
							:options="{ suppressScrollX: true }"
							class="scroll"
							style="max-height: 60vh; position: static"
						>
							<v-list class="border border-secondary" subheader three-line>
								<v-list-item>
									<v-list-item-avatar v-if="relatedDetails">
										<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
									</v-list-item-avatar>
									<v-list-item-content v-if="relatedDetails">
										<v-list-item-title class="font-size-16">
											<Chip color="blue darken-4" :text="relatedDetails.barcode"></Chip>
										</v-list-item-title>
										<v-list-item-subtitle class="font-size-14">
											<b>{{ relatedDetails.name }}</b>
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action class="margin-auto" v-if="relatedDetails">
										<v-list-item-action-text class="font-size-16"
											><b>Cost</b> {{ relatedDetails.cost_price_formatted }}
										</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</perfect-scrollbar>
					</v-col>
					<template v-if="type != 'Perishable'">
						<!-- <v-col md="3" class="my-auto py-0">
							<label :for="`checkout-duration-${uniqFieldId}`" class="btx-label mt-3">Duration</label>
						</v-col>
						<v-col md="9" class="py-0">
							<v-layout>
								<v-flex md6>
									<NumberInput hide-details :disabled="pageLoading" :loading="pageLoading"
										:id="`checkout-duration-${uniqFieldId}`" placeholder="Duration"
										v-model="checkout.duration" v-on:keyup="updateDuration()"></NumberInput>
								</v-flex>
								<v-flex md6>
									<SelectInput hide-details :items="durationList" :disabled="pageLoading"
										:loading="pageLoading" placeholder="Duration" v-model="checkout.duration_type"
										v-on:change="updateDuration()"></SelectInput>
								</v-flex>
							</v-layout>
						</v-col> -->
						<v-col md="3" class="my-auto py-0">
							<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3 required">Damage Date</label>
						</v-col>
						<v-col md="9" class="py-0">
							<DateTimePicker
								hide-details
								:disabled="(checkout.indefinitely ? true : false) || pageLoading"
								:loading="pageLoading"
								:id="`return-on-${uniqFieldId}`"
								required
								placeholder="Return On"
								v-model="checkout.return_on"
							></DateTimePicker>
						</v-col>
						<v-col md="3" class="py-0">
							<label :for="`reason-${uniqFieldId}`" class="btx-label mt-3">Reason</label>
						</v-col>
						<v-col md="9" class="py-0">
							<SelectInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								append-outer-icon="mdi-cog"
								:items.sync="reasonLists"
								placeholder="Select Reason"
								item-text="value"
								item-value="value"
								:id="`reason`"
								v-on:click:append-outer="manageReasonDialog = true"
								v-model="checkout.damage_reason"
							></SelectInput>
						</v-col>
						<!-- v-col md="3" class="py-0"> </v-col>
						<v-col md="9" class="py-0">
							<v-checkbox dense hide-details label="Issue indefinitely" class="mt-3 mb-0 p-0"
								:true-value="1" :false-value="0" v-model="checkout.indefinitely"></v-checkbox>
						</v-col>
						<v-col md="3" class="py-0"> </v-col>
						<v-col md="9" class="py-0">
							<v-checkbox dense hide-details label="Issue Loan" class="mt-3 mb-0 p-0"
								:true-value="1" :false-value="0" v-model="checkout.has_loan"></v-checkbox>
						</v-col -->
					</template>
					<!-- <template v-if="type == 'Asset Stock' || type == 'Perishable'">
						<v-col md="3" class="my-auto py-0">
							<label :for="`member-${uniqFieldId}`" class="btx-label mt-3 required">Quantity</label>
						</v-col>
						<v-col md="9" class="py-0">
							<NumberInput hide-details :disabled="pageLoading" :loading="pageLoading"
								:id="`checkout-duration-${uniqFieldId}`" placeholder="Quantity"
								:rules="[vrules.required(checkout.quantity, 'Group')]" :class="{
									required: !checkout.quantity,
								}" v-model="checkout.quantity"></NumberInput>
						</v-col>
					</template> -->

					<!-- <v-col md="3" class="py-0">
						<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Comments</label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput hide-details :disabled="pageLoading" :loading="pageLoading"
							:id="`comment-${uniqFieldId}`" placeholder="Comments" v-model="checkout.comment">
						</TextAreaInput>
					</v-col> -->
					<v-col md="3" class="my-auto py-0">
						<label for="attachment" class="btx-label mt-2">Attachment</label>
					</v-col>
					<v-col md="9" class="py-0">
						<template v-for="(data, index) in btx_attachments">
							<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<v-col md="5" class="py-0">
									<v-file-input
										v-model="data.file"
										placeholder="Click here to select files"
										color="blue darken-4"
										multiple
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-on:change="limitAttachment($event, index)"
									></v-file-input>
								</v-col>
								<v-col md="5" class="py-0">
									<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="btx_attachments.length < 2"
										v-on:click="removeFile(index)"
										class="white--text mt-3"
										depressed
										color="red darken-4"
										tile
										style="min-width: 18px !important; margin-left: -8px"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="btx_attachments.length > 2"
										v-on:click="addMore()"
										class="white--text mt-3"
										depressed
										color="blue darken-4"
										tile
										style="min-width: 18px !important; margin-left: -8px"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
					</v-col>
					<v-col md="3" class="py-0">
						<label :for="`reason-${uniqFieldId}`" class="btx-label mt-3">Remark</label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="`reason-${uniqFieldId}`"
							placeholder="Reason"
							v-model="checkout.reason"
						>
						</TextAreaInput>
					</v-col>
					<template v-if="false">
						<v-col md="3" class="py-0 mt-2">
							<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Signature</label>
						</v-col>
						<v-col md="9" class="py-0">
							<div class="signature-pad" id="signature-pad">
								<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
									<canvas ref="cc_signature"></canvas>
								</div>
							</div>
						</v-col>
					</template>

					<template v-if="showReservation">
						<v-col md="12" class="font-level-3-bold pb-3 text-black border-bottom-light-grey mt-4 py-0">
							Reservations
						</v-col>
						<v-col md="12" class="py-0">
							<div
								class="mt-3 overflow-y border-light-grey"
								style="max-height: calc(100vh - 337px); min-height: 200px"
							>
								<table width="100%" class="detail-table table-head-sticky">
									<thead>
										<tr>
											<th width="40%" class="p-2 font-level-1-bold light-blue-bg">Requested By</th>
											<th width="30%" class="p-2 font-level-1-bold light-blue-bg">From</th>
											<th width="30%" class="p-2 font-level-1-bold light-blue-bg">To</th>
										</tr>
									</thead>
									<tbody v-if="reservationLoading">
										<tr>
											<td colspan="3">
												<v-progress-linear color="orange darken-2" indeterminate height="4">
												</v-progress-linear>
											</td>
										</tr>
									</tbody>
									<tbody v-else-if="reservationList.length">
										<tr v-for="(row, index) in reservationList" :key="index">
											<td width="40%" class="px-2 py-3 font-level-1 text-black border-top-light-grey">
												{{ row.member_display_name }}
											</td>
											<td width="30%" class="px-2 py-3 font-level-1 text-black border-top-light-grey">
												{{ row.reserve_from_formatted }}
											</td>
											<td width="30%" class="px-2 py-3 font-level-1 text-black border-top-light-grey">
												{{ row.reserve_to_formatted }}
											</td>
										</tr>
									</tbody>
									<tfoot v-else>
										<tr>
											<td colspan="3">
												<p class="m-0 row-not-found text-center">
													<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
													Uhh... There are no reservation at the moment.
												</p>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</v-col>
					</template>
				</v-row>
			</v-form>
			<template v-if="manageReasonDialog">
				<ManageReason
					:dialog="manageReasonDialog"
					:designation="reasonLists"
					v-on:close-dialog="manageReasonDialog = false"
					v-on:get-designation="getDesignations"
				></ManageReason>
			</template>
		</template>
		<template v-slot:action>
			<template v-if="cartUuid">
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateCart()"
				>
					Update Cart
				</v-btn>
			</template>
			<template v-else>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="addToCart()"
				>
					Save
				</v-btn>
			</template>
			<template v-if="false">
				<template v-if="checkoutUuid">
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onUpdateSubmit()"
					>
						Issue Update
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Issue
					</v-btn>
				</template>
			</template>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import ManageReason from "@/view/components/ManageReason";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
//import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import { EventBus } from "@/core/event-bus/event.bus";
import SignaturePad from "signature_pad";
import { CART_COUNT } from "@/core/services/store/config.module";
import { UploadFiles } from "@/core/lib/upload.lib";
import store from "@/core/services/store";
import MomentJS from "moment-timezone";
import ObjectPath from "object-path";
import TextInput from "@/view/components/TextInput";
//import AutoCompleteInputMember from "@/view/components/AutoCompleteInputMember";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "checkout-template",
	title: "Issue Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		reservationUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		checkoutDialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
		cartUuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		typeUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param && !this.uuid) {
					this.getRelatedDetails(param);
				}
			},
		},
		checkoutDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.init();
				}
			},
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			reservationLoading: false,
			manageReasonDialog: false,
			reasonLists: [],
			locationList: [],
			memberList: [],
			signature: null,
			attachment: [],
			reservationList: [],
			showReservation: false,
			checkoutUuid: null,
			relatedDetails: {},
			checkout: {
				return_on: null,
				damage_reason: null,
				reason: null,
				comment: null,
			},
			btx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
		};
	},
	components: {
		Dialog,
		//NumberInput,
		SelectInput,
		DateTimePicker,
		ImageTemplate,
		TextAreaInput,
		ManageReason,
		Chip,
		//AutoCompleteInput,
		TextInput,
		//AutoCompleteInputMember
	},
	methods: {
		addMore() {
			this.btx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.btx_attachments.splice(index, 1);
			if (this.btx_attachments.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.btx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.btx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			const next_week = MomentJS().add(7, "days");
			this.checkout = new Object({
				duration: 1,
				duration_type: "week",
				return_on: this.formatDateTimeRaw(next_week),
				damage_reason: null,
				indefinitely: 0,
				has_loan: 0,
				member: 0,
				location: 0,
				comment: null,
				ignore_conflict: 0,
				ful_fill: 1,
			});
			// this.initSignature();
			if (this.uuid) {
				this.getCheckedOut();
			}
			if (this.cartUuid) {
				this.getCartCheckedOut();
			}
		},
		initSignature() {
			const _this = this;
			_this.$nextTick(() => {
				let ccanvas = _this.$refs["cc_signature"];
				let ccanvasDiv = _this.$refs["signature_div"];
				ccanvas.setAttribute("width", 500);
				ccanvasDiv.setAttribute("style", "width:" + "500px");
				_this.signature = new SignaturePad(ccanvas);
			});
		},
		getRelatedDetails(typeUuid) {
			this.pageLoading = true;
			ApiService.get(`asset/${typeUuid}`)
				.then(({ data }) => {
					this.relatedDetails = data;
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCheckedOut() {
			this.pageLoading = true;
			ApiService.get(`checkout/${this.uuid}`)
				.then(({ data }) => {
					this.checkout = new Object({
						duration: 1,
						duration_type: "week",
						return_on: this.formatDateTimeRaw(data.return_on),
						damage_reason: this.data.damage_reason,
						indefinitely: data.indefinitely,
						has_loan: data.has_loan,
						member: data.member,
						location: data.location,
						comment: data.description,
						ignore_conflict: data.ignore_conflict,
						ful_fill: data.ful_fill,
					});
					this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";
					this.checkoutUuid = data.uuid;

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCartCheckedOut() {
			this.pageLoading = true;
			ApiService.get(`cart/${this.cartUuid}`)
				.then(({ data }) => {
					this.checkout = new Object({
						duration: 1,
						duration_type: "week",
						return_on: this.formatDateTimeRaw(data.reserve_to),
						damage_reason: this.data.damage_reason,
						indefinitely: data.indefinitely,
						has_loan: data.has_loan,
						member: data.member,
						location: data.location,
						comment: data.description,
						ignore_conflict: data.ignore_conflict,
						ful_fill: data.ful_fill,
					});
					this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";
					this.checkoutUuid = data.uuid;

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDuration() {
			let days = 1;

			let { duration_type, duration } = this.checkout;

			switch (duration_type) {
				case "day":
					days = toSafeInteger(duration);
					break;
				case "week":
					days = 7 * toSafeInteger(duration);
					break;
				case "month":
					days = 30 * toSafeInteger(duration);
					break;
			}

			this.$nextTick(() => {
				const date = MomentJS().add(days, "days");
				this.checkout.return_on = this.formatDateTimeRaw(date);
			});
		},
		manageLocation() {
			console.log("manageLocation");
		},
		getReservations() {
			this.reservationList = [];

			if (!this.showReservation) {
				return false;
			}

			this.reservationLoading = true;

			ApiService.get(`${this.endpoint}/${this.typeUuid}/reservation`)
				.then(({ data }) => {
					this.reservationList = data;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.reservationLoading = false;
				});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.checkoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.checkoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let requestUrl = `${this.endpoint}/${this.typeUuid}/checkout`;
			if (this.reservationUuid && this.reservationUuid != "") {
				requestUrl = `${this.endpoint}/${this.typeUuid}/reservation/${this.reservationUuid}/approve`;
			}
			this.checkout.reservation_uuid = this.reservationUuid;
			this.checkout.signature = null; //this.signature.toDataURL();
			ApiService.patch(requestUrl, this.checkout)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-reservation", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addToCart() {
			const _this = this;

			let requestUrl = `damage/${this.relatedDetails.id}`;
			const cartData = {
				return_on: _this.checkout.return_on,
				attachment: _this.btx_attachments,
				reason: _this.checkout.reason,
				damage_reason: _this.checkout.damage_reason,
			};

			ApiService.post(requestUrl, cartData)
				.then(() => {
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! Damage Asset has been successfully Save.` },
					]);
					_this.$emit("success", true);
					EventBus.$emit("reload:cart", true);
					EventBus.$emit("reload:asset-reservation", true);
					store.dispatch(CART_COUNT);
				})
				.catch((error) => {
					_this.$emit("error", error);
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.checkoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.checkoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let requestUrl = `${this.endpoint}/${this.typeUuid}/checkout/${this.checkoutUuid}`;
			this.checkout.reservation_uuid = this.reservationUuid;
			ApiService.put(requestUrl, this.checkout)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-reservation", true);
					_this.checkout = {
						duration: 1,
						duration_type: "week",
						return_on: null,
						damage_reason: null,
						indefinitely: 0,
						has_loan: 0,
						member: 0,
						location: 0,
						comment: null,
						ignore_conflict: 0,
						ful_fill: 1,
						reservation_uuid: null,
					};
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateCart(replace = false) {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.checkoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.checkoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let requestUrl = `cart/${this.typeUuid}/checkout/${this.cartUuid}`;
			const cartData = {
				reserve_to: this.checkout.return_on,
				indefinitely: this.checkout.indefinitely,
				has_loan: this.checkout.has_loan,
				member: this.checkout.member,
				attachment: this.checkout.attachment,
				reason: this.checkout.reason,
				comment: this.checkout.comment,
				ignore_conflict: this.checkout.ignore_conflict,
				ful_fill: this.checkout.ful_fill,
				signature: null, // this.signature.toDataURL(),
				related_type: this.type,
				quantity: this.checkout.quantity,
				replace: replace,
			};

			ApiService.put(requestUrl, cartData)
				.then(({ data }) => {
					if (data && data.exists) {
						this.$emit("close", true);
						this.$swal({
							title: "Another member details exist in cart, Do you want to replace it?",
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Replace",
							confirmButtonClass: "bg-danger",
						}).then((result) => {
							if (result.isConfirmed) {
								this.updateCart(true);
							}
						});
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${this.type} has been successfully added to cart.` },
						]);
						this.$emit("success");
						EventBus.$emit("reload:asset-reservation", true);
						store.dispatch(CART_COUNT);
					}
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		this.reasonLists = this.localDB("reasons", []);
		this.memberList = this.localDB("members", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
